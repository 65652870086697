const AppConfig = {
  app: {
    version: "1.1.3",
    environment: "DEV",
    nodeEnv: "development",
  },
  aws: {
    cognito: {
      userPoolId: "us-east-1_dSg9rDdRV",
      clientId: "7ve4m5jb7r0as75j8qgphgl6r5",
      hostedUiUrl: "https://devsaveonexpress.auth.us-east-1.amazoncognito.com",
    },
    restApi: {
      apiUrl: "https://api.dev.saveonexpress.ca",
    },
    graphql: {
      apiUrl: process.env.REACT_APP_GRAPHQL_API_URL,
      apiKey: process.env.REACT_APP_GRAPHQL_API_KEY,
      region: process.env.REACT_APP_GRAPHQL_API_REGION,
    },
  },
  features: {
    showOrderTypeColumn: "false" === "true",
    emailInvoiceCCList: "finance@inex.ca",
  },
  logRocket: {
    id: " ylvrtg/soe",
  },
}

export default AppConfig
